@import 'common';

body{background-color: #fff;}

#mainWrapper{
    .gradient-overlay:after{content:'';
        @include absolute(top 0 right 0 bottom 0 left 0);background-color:rgba(0,0,0,0.2);
        
    }
}

#homeVideo{overflow:hidden;
    @include screen-tab{/*height:559px;*/height:100vh;min-height:559px;}
    .bg{width:100%;visibility:hidden;
        @include screen-desktop{height:100vh;}
        @include screen-tab{height:100%;width:auto;left:50%;position:relative;margin-left:-493px;}
    }
    .non-video-bg{position:absolute;left: 0;top: 0;width: 100%;height: 100%;
        /*background:url(../images/home/demo-video-bg.jpg) no-repeat center center/auto 100%;
        @include screen-desktop{background-size:cover;}*/
        /*background:url(../images/home/bg_grass2.jpg) no-repeat center center/auto 100%;*/
        > div{@include absolute(top 0 right 0 bottom 0 left 0);width:100%;height: 100%;
            background-repeat:no-repeat;background-position:center center;background-size:auto 100%;
            @include screen-desktop{background-size:cover;}
        }
    }
    #heroBannerControl{top:50%;@include transform(translateY(-50%));width: 100%;z-index:1;
        > a{display:block;
            img{opacity:0.25;@include transition(all 0.3s ease-in-out);
                &:hover{opacity:1;}
            }
        }
        > a:first-child{@include absolute(right 55px top 0);
            @include screen-tab{right:10px;
                > img{width:30px;}
            }
            @include screen-mobile{right:10px;
                > img{width:20px;}
            }
        }
        > a:last-child{@include absolute(left 55px top 0);
            @include screen-tab{left:10px;
                > img{width:30px;}
            }
            @include screen-mobile{left:10px;
                > img{width:20px;}
            }
        }
    }
    #heroBanner{top:50%;@include transform(translateY(-50%));width:100%;
        h1{font-size:53px;line-height:1.2;letter-spacing:5px;max-width:700px;margin:0 auto 12px auto;
            @include screen-mobile{font-size:22px;padding:0 30px;}
            &.default{max-width:none;}
            img{margin-bottom:28px;
                @include screen-mobile{margin-bottom:8px;}
                &.mobile{max-width:100%;padding:20px;}
            }
            /*span{display:none;}*/
        }
        p{font-size:15px;line-height:1.7;max-width:700px;margin:0 auto;padding:0 50px;letter-spacing:1px;
            @include screen-mobile{font-size:13px;}
            &.default{font-size:18px;line-height:1.95;font-weight:bold;letter-spacing:5px;
                @include screen-tab{font-size:14px;}
            }
            a{margin-top:30px;}
        }
    }
    .parallax{width:100%;height:100%;
        #videoWrapper{height:100%;background: url(../images/home/demo-video-bg.jpg) no-repeat center center/auto 100%;background-size: cover;}
    }
    img.parallax{width:auto;
        
    }
    /*video{width: auto !important;height: 100% !important;position:absolute;left:50%;top:0;@include transform(translateX(-50%));}*/
    video{min-width:100%;min-height:100%;width:auto;height:auto;@include absolute(left 50% top 50%);@include transform(translate(-50%, -50%));}
    .single-line{display:inline-block;max-width: 100%;padding: 0 30px;
        @media only screen and (max-width: 640px) {
            display:none;
        }
    }
    .double-line{display:none;max-width: 100%;
        @include screen-mobile{}
        @media only screen and (max-width: 640px) {
            display:inline-block;
        }
    } 
    
    svg{ position:absolute;left:calc(50% - 50px);bottom:60px; 
        @include screen-mobile{bottom:10px;@include transform(scale(0.6));@include transform-origin((center, bottom));}
        #circleS1, #circleS2, #circleM1, #circleM2, #circleL1, #circleL2{ visibility:hidden; }
        &#circleArrowWrapper{cursor:pointer;
            &:hover{
                #circleArrow{
                    @include transform(translateY(5px));
                }
            }
            #circleArrow{
                @include transition(all 0.2s ease-in-out);
                /*@include animation('jump 1.5s ease-out infinite');*/
                
            }
        }
    }
    .scroll-btn{@include absolute(left calc(50% - 50px) bottom 60px);
        @include screen-mobile{bottom:10px;@include transform(scale(0.6));@include transform-origin((center, bottom));}
        img{@include absolute(left 0 bottom 0);visibility: hidden;}
    }

    // special event, 20210626 - 20210713
    .special-event-popup-wrapper {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0,0,0,0.8);
        z-index: 999;

        .btn-close {
            position: absolute;
            top: 30px;
            right: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            z-index: 5;
            cursor: pointer;
        }

        .special-event-popup {
            position: fixed;
            top: 50%;
            left: 50%;
            width: 90%;
            max-width: 400px;
            transform: translate(-50%, -50%);
            z-index: 99;

            .title {
                margin-bottom: 20px;
                color: #fff;
                font-size: 20px;
                text-align: center;
                text-transform: uppercase;
            }
    
            img {
                width: 100%; 
            }
        }
    }
}


section{display:flex;-ms-flex-align:center;-webkit-align-items: center;-webkit-box-align: center;align-items: center;
    z-index: 10;position: relative;
    background:url(../images/w-pattern_bg.svg) #ffffff repeat-y 0% 0%/50% auto;
    &.right-content{
        background-position:75% 0%;
    }
    > div{width:50%;
        &.desc{
            > div{max-width:490px;padding:35px 30px;display: inline-block;
                @include screen-tab{padding:50px 30px 55px 30px;}
                h2{font-size:30px;line-height:1.15;margin-bottom:10px;letter-spacing:3.7px;
                    @include screen-mobile{@include font(23px, 1.25, 50);margin-bottom:14px;}
                }
                p{font-size:15px;line-height:1.7;margin-bottom:35px;letter-spacing:1px;
                    @include screen-mobile{font-size:13px;margin-bottom:28px;}
                }
            }
        }
        &.img{
            > img{width:100%;}
        }
    }
    @include screen-tab{flex-direction: column;
        > div{width:100%;}
    }
}

#about{
    @include screen-tab{flex-direction: column-reverse;background-position: right top;}
    > div{
        &.img{background:url(../images/home/about.jpg) no-repeat center center;background-size:cover;
            img{width:100%;
                @include screen-mobile{max-height:260px;}
            }
        }
    }
}

#happening{
    
}

#location{background-position: right top;
    @include screen-tab{flex-direction: column-reverse;}
    > div{
        &.img{background:url(../images/home/location.jpg) no-repeat center center;background-size:cover;
            img{width:100%;
                @include screen-mobile{max-height:260px;}
            }
            iframe{left:0;top:0;border:0;width:100%;height:100%;}
            h2{left:40px;bottom:40px;font-size:30px;line-height:1.15;letter-spacing:3.7px;
                @include screen-mobile{left:20px;bottom:20px;@include font(23px, 1.25, 50);}
            }
        }
        &.desc{
            img.slogan{width:300px;max-width:100%;}
        }
    }
}

.parallax{
    position:absolute;z-index:0;left:0;top:0;
}